import React from "react";
import Header from "../header/Header";
import { Outlet } from "react-router-dom";
import Footer from "../footer/Footer";
import "./style/Main.scss";

const Layout = () => {
  return (
    <>
      <Header />
      <main className="main-container">
        <Outlet />
      </main>

      <Footer />
    </>
  );
};

export default Layout;
