import React from "react";
import { NavLink } from "react-router-dom";

const Brand = () => {
  return (
    <header className="brand-wrapper">
      <NavLink className="brand" to="/">
        <img
          className="brand-image"
          src="./images/smitesh-tamboli.png"
          alt="Smitesh Tamboli"
        />
        <h2 className="brand-text">Smitesh Tamboli</h2>
      </NavLink>
    </header>
  );
};

export default Brand;
