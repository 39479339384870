import React from "react";
import "./style/Cpp.scss";
import CppYTItem from "../components/cpp/CppYTItem";

const Cpp = () => {
  return (
    <div className="cpp-page-container">
      <div className="cpp-blogs-grid-wrapper">
        <CppYTItem
          yt_link="https://youtu.be/ejQQFv811L0"
          yt_thumbnail="Monitoring System Thumnail.png"
          yt_thumbnail_alt="Monitoring System Project in C++"
        />
        <CppYTItem
          yt_link="https://youtu.be/wJq037haYlU"
          yt_thumbnail="unique_ptr-Thumbnail.png"
          yt_thumbnail_alt="unique_ptr in C++"
        />

        <CppYTItem
          yt_link="https://youtube.com/shorts/ywd54Oli1wY?feature=share"
          yt_thumbnail="nullptr.png"
          yt_thumbnail_alt="nullptr is better than NULL in C++"
        />

        <CppYTItem
          yt_link="https://youtube.com/shorts/XUzlO6JCA1s?feature=share"
          yt_thumbnail="static as virtual.png"
          yt_thumbnail_alt="Why virtual function can't be static in C++"
        />

        <CppYTItem
          yt_link="https://youtube.com/shorts/9DuDnD5odHA?feature=share"
          yt_thumbnail="malloc-vs-new.png"
          yt_thumbnail_alt="malloc vs new in C++"
        />
      </div>
    </div>
  );
};

export default Cpp;
