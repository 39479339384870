import React from "react";
import "./Footer.scss";

const Footer = () => {
  return (
    <footer className="footer-container">
      <h5 className="footer-copyrights">
        © 2024 Smitesh Tamboli. All Rights Reserved.
      </h5>
    </footer>
  );
};

export default Footer;
