import React from "react";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  return (
    <nav className="navbar-container">
      <NavLink to="cpp" className="nav-item">
        C++
      </NavLink>
      {/*<NavLink to="/" className="nav-item">
        About Me
      </NavLink>
      <NavLink to="blogs" className="nav-item">
        Blogs
      </NavLink>
      <NavLink to="projects" className="nav-item">
        Projects
      </NavLink>
      <NavLink to="contactme" className="nav-item">
        Contact Me
      </NavLink>*/}
    </nav>
  );
};

export default Navbar;
