import React from "react";
import "./CppYTItem.scss";
import { Link } from "react-router-dom";

const CppYTItem = ({ yt_link, yt_thumbnail, yt_thumbnail_alt }) => {
  return (
    <div className="yt-item-wrapper">
      <Link
        className="yt-item-link"
        to={yt_link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={"./images/cpp/yt/" + yt_thumbnail} alt={yt_thumbnail_alt} />
      </Link>
    </div>
  );
};

export default CppYTItem;
