import React from "react";
import "./style/Common.scss";

const Projects = () => {
  return (
    <div className="blog-page-container">
      <img src="./images/work-in-progress.png" alt="Work In Progress" />
    </div>
  );
};

export default Projects;
